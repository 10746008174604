import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import RatingSection from "../../components/doctor/RatingSection";
import { FaPhone, FaChevronUp, FaChevronDown } from "react-icons/fa";
import * as reviewService from "../../api/reviewService";
import { DoctorType, ReviewType, UserType } from "../../types";
import { notifyErr, notifySuccess } from "../../components/MessageUtils";
import { fetchDoctorBySlug } from "../../actions/doctorActions";

const DoctorDetailsPage = () => {
  const { doctorSlug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [doctor, setDoctor] = useState<DoctorType | UserType | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showReviews, setShowReviews] = useState(false);
  const [reviews, setReviews] = useState<ReviewType[]>([]);
  const token = useSelector((state: any) => state.userInfo?.token);
  // const doctorType = "doctor";
  const userLogin = useSelector((state: any) => state.userLogin);
  const { userInfo } = userLogin;
  const [isFollowing, setFollowing] = useState<boolean>(false);
  const codeLng = window.location.pathname.substring(1, 3) || "fr";

  const doctorDetails = useSelector((state: any) => state.doctorDetails);
  const {
    loading: loadingDoctor,
    error: errorDoctor,
    doctor: fetchedDoctor,
  } = doctorDetails;

  useEffect(() => {
    if (doctorSlug) {
      dispatch<any>(fetchDoctorBySlug(doctorSlug));
    }
  }, [dispatch, doctorSlug]);

  useEffect(() => {
    if (fetchedDoctor) {
      setDoctor(fetchedDoctor);

      setLoading(false);
    }
  }, [fetchedDoctor, reviews]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        if (doctor) {
          let doctorId: string | undefined;
          let determinedDoctorType: string | undefined;

          if ("doctor_profile" in doctor && doctor.doctor_profile) {
            doctorId = doctor.doctor_profile.id;
            determinedDoctorType = "doctor_profile";
          } else if ("id" in doctor) {
            doctorId = doctor.id;
            determinedDoctorType = "doctor";
          }

          if (doctorId && determinedDoctorType) {
            const fetchedReviews = await reviewService.loadReviews(
              doctorId,
              token,
              determinedDoctorType
            );
            setReviews(fetchedReviews);
          }
        }
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchReviews();
  }, [doctor, token]);

  // useEffect(() => {
  //   const fetchReviews = async () => {
  //     let doctor_profile = "doctor";
  //     if (doctor && "doctor_profile" in doctor && doctor.doctor_profile) {
  //       doctor_profile = "doctor_profile";
  //     } else if (doctor && "doctor.id" in doctor) {
  //       doctor_profile = "doctor_profile";
  //     }
  //     if (doctor && "id" in doctor) {
  //       const fetchedReviews = await reviewService.loadReviews(
  //         doctor.id,
  //         token,
  //         doctor_profile
  //       );
  //       setReviews(fetchedReviews);
  //     } else if (doctor && "doctor_profile" in doctor) {
  //       const doctorWithProfile = doctor as UserType;
  //       if (doctorWithProfile.doctor_profile) {
  //         const fetchedReviews = await reviewService.loadReviews(
  //           doctorWithProfile.doctor_profile.id,
  //           token,
  //           "doctor_profile"
  //         );
  //         setReviews(fetchedReviews);
  //       }
  //     }
  //   };
  //   fetchReviews();
  // }, [doctor, token]);

  // useEffect(() => {
  //   const fetchReviews = async () => {
  //     if (doctor?.id) {
  //       const fetchedReviews = await reviewService.loadReviews(
  //         doctor.id,
  //         token,
  //         doctorType
  //       );
  //       setReviews(fetchedReviews);
  //     }
  //   };
  //   fetchReviews();
  // }, [doctor, token]);

  useEffect(() => {
    const checkFollowing = async () => {
      const doctorType =
        doctor && "doctor_profile" in doctor ? "doctor_profile" : "doctor";

      try {
        const response = await axios.get(
          `/api/users/follow/doctor/${doctor?.id}/is-following/${doctorType}/`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
        );
        console.log(
          "response.data--",
          response.data.isFollowing,
          doctorType,
          doctor?.id
        );

        setFollowing(response.data.isFollowing);
      } catch (error) {
        console.error(
          "An error occurred while checking following status",
          error
        );
      }
    };
    if (userInfo) {
      checkFollowing();
    }
  }, [doctor]);
  // useEffect(() => {
  //   const checkFollowing = async () => {
  //     try {
  //       if (doctor) {
  //         const response = await axios.get(
  //           `/api/doctors/${doctor.id}/followers/`,
  //           {
  //             headers: {
  //               Authorization: `Bearer ${token}`,
  //             },
  //           }
  //         );
  //         setFollowing(response.data.isFollowing);
  //       }
  //     } catch (error) {
  //       console.error("Error checking following status:", error);
  //     }
  //   };
  //   checkFollowing();
  // }, [doctor, token]);

  const handleFollow = async () => {
    if (doctor) {
      try {
        const response = await axios.post(
          `/api/doctors/${doctor.id}/follow/`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setFollowing(true);
        notifySuccess(t("doctor_followed_success"));
      } catch (error) {
        notifyErr(t("doctor_followed_error"));
      }
    }
  };

  const handleUnfollow = async () => {
    if (doctor) {
      try {
        const response = await axios.post(
          `/api/doctors/${doctor.id}/unfollow/`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setFollowing(false);
        notifySuccess(t("doctor_unfollowed_success"));
      } catch (error) {
        notifyErr(t("doctor_unfollowed_error"));
      }
    }
  };

  const toggleReviews = () => {
    setShowReviews((prevShowReviews) => !prevShowReviews);
  };

  if (loading || loadingDoctor) {
    return <div>Loading...</div>;
  }

  if (error || errorDoctor) {
    return <div>Error: {error || errorDoctor}</div>;
  }

  //   return (
  //     <div>
  //       {doctor && (
  //         <div>
  //           <h1>{doctor.first_name}</h1>
  //           <p>{doctor.speciality}</p>
  //           <p>{doctor.phone}</p>
  //           <button onClick={isFollowing ? handleUnfollow : handleFollow}>
  //             {isFollowing ? t("unfollow") : t("follow")}
  //           </button>
  //           <button onClick={toggleReviews}>
  //             {showReviews ? <FaChevronUp /> : <FaChevronDown />}
  //             {t("reviews")}
  //           </button>
  //           {showReviews && (
  //             <div>{/* <RatingSection reviews={reviews} /> */}</div>
  //           )}
  //         </div>
  //       )}
  //       <ToastContainer />
  //     </div>
  //   );
  // };

  // export default DoctorDetailsPage;
  const followDoctor = async (doctor: DoctorType | UserType) => {
    const doctorType =
      doctor && "doctor_profile" in doctor ? "doctor_profile" : "doctor";
    if (userInfo && "id" in doctor) {
      try {
        const newDoctorFollowing = {
          user: userInfo.id,
          doctor: doctor.id,
          doctor_type: doctorType,
        };
        const axiosConfig = {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
          params: { user: userInfo },
        };

        await axios.post(
          `/api/users/follow/doctor/${doctor.id}/`,
          newDoctorFollowing,
          axiosConfig
        );

        notifySuccess(t("Doctor added successfully!"));
        setFollowing(true);
      } catch (error: any) {
        let errMsg = error.response.data.detail;
        notifyErr(t("Error." + errMsg));
      }
    } else {
      navigate(`/${codeLng}/login`);
    }
  };

  const getDescription = () => {
    if (doctor && "description" in doctor) {
      return doctor.description || "";
    }
    if (
      doctor &&
      "doctor_profile" in doctor &&
      doctor?.doctor_profile?.description
    ) {
      return doctor.doctor_profile.description || "";
    }
    return "";
  };

  const contactNow = () => {
    navigate(`/${codeLng}/login/`);
  };
  const getImageUrl = (image: any) => {
    if (image instanceof File) {
      return URL.createObjectURL(image);
    }
    return image || ""; // Return an empty string if no image is provided
  };

  return (
    <div className="doctor-details-page">
      <ToastContainer />

      {doctor && (
        <div className="bestabib__doctor-details">
          <div>
            <button
              className="bestabib__doctor-details-btn-addToMydoctors"
              onClick={() => followDoctor(doctor)}
              disabled={isFollowing ?? true}
            >
              {t("Doctor_Details.Follow")}
            </button>

            {error && <p>{error}</p>}
          </div>
          <div className="bestabib__doctor-details-card-content">
            <img
              src={
                doctor && "gender" in doctor && doctor.gender === "F"
                  ? "https://tallshadowawsbucket.s3.eu-west-3.amazonaws.com/bestabib/default-doctor-f.jpg"
                  : "https://tallshadowawsbucket.s3.eu-west-3.amazonaws.com/bestabib/default-doctor-m.jpg"
              }
              // src={
              //   doctor && "image" in doctor
              //     ? getImageUrl(doctor.image)
              //     : "doctor_profile" in doctor
              //     ? getImageUrl(doctor?.doctor_profile?.image)
              //     : doctor && "gender" in doctor && doctor.gender === "F"
              //     ? "https://tallshadowawsbucket.s3.eu-west-3.amazonaws.com/bestabib/default-doctor-f.jpg"
              //     : "https://tallshadowawsbucket.s3.eu-west-3.amazonaws.com/bestabib/default-doctor-m.jpg"
              // }
              alt={
                doctor && "first_name" in doctor && doctor.first_name
                  ? `${doctor.first_name} ${doctor.last_name}`
                  : "Doctor"
              }
              width={88}
              height={88}
              className="bestabib__doctor-details-card-photo"
            />
            <div className="bestabib__doctor-details-info">
              <h3 className="bestabib__doctor-details-card-info-title">
                Dr. {doctor.first_name} {doctor.last_name}
              </h3>
              <p className="bestabib__doctor-details-card-info-speciality">
                {doctor && "speciality" in doctor
                  ? doctor.speciality
                  : doctor &&
                    "doctor_profile" in doctor &&
                    doctor?.doctor_profile?.speciality}
              </p>
              <div className="bestabib__doctor-details-card-info-address">
                {doctor?.address?.street && <p>{doctor.address.street}</p>}
                {doctor?.address?.postal_code && (
                  <p>{doctor.address.postal_code}</p>
                )}
                {doctor?.address?.city && <p>{doctor.address.city}</p>}
              </div>
            </div>
            <div className="bestabib__doctor-details-card-action">
              {userInfo ? (
                <>
                  {doctor?.phone && (
                    <p className="doctor-card-phone ">{doctor?.phone}</p>
                  )}
                  {/* {doctor?.email && (
                  <p className="doctor-card-email">{doctor?.email}</p>
                )} */}
                </>
              ) : (
                <>
                  <button
                    className="bestabib__doctor-card-details_contact"
                    onClick={contactNow}
                  >
                    {t("Doctor.Call")}
                    <FaPhone />
                  </button>
                </>
              )}
            </div>
          </div>

          <div className="bestabib__doctor-details-card-info-ratings">
            {doctor && (
              <RatingSection
                doctorId={
                  "doctor_profile" in doctor && doctor.doctor_profile
                    ? doctor.doctor_profile.id
                    : doctor.id
                }
                doctorType={
                  "doctor_profile" in doctor && doctor.doctor_profile
                    ? "doctor_profile"
                    : "doctor"
                }
                isDoctorDetails={true}
              />
            )}
          </div>

          <div className="bestabib__doctor-details-reviews">
            <button
              onClick={() => setShowReviews(!showReviews)}
              className="reviews-toggle-button"
            >
              {t("Messages.Reviews")}{" "}
              {showReviews ? <FaChevronUp /> : <FaChevronDown />}
            </button>
            {showReviews &&
              (reviews.length > 0 ? (
                reviews.map((review) => (
                  <div key={review.id} className="review-item">
                    <div className="review-date">
                      {new Date(review.created_at).toLocaleDateString()}
                    </div>
                    <div className="review-text">{review.review}</div>
                  </div>
                ))
              ) : (
                <p>{t("Messages.No_reviews_yet.")}</p>
              ))}
          </div>

          <hr />
          {getDescription() && (
            <>
              <h2 className="doctorMarok__doctor-details">
                {t("Doctor.Description")}
              </h2>
              <div
                className="bestabib__doctor-description-scroll"
                dangerouslySetInnerHTML={{
                  __html: getDescription(),
                }}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default DoctorDetailsPage;
