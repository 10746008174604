import React from "react";
import styles from "./Pagination.module.css";

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  currentPage,
  onPageChange,
}) => {
  return (
    <div className={styles.pagination}>
      {[...Array(totalPages)].map((_, index) => {
        const isOneOfFirstThree = index < 3;
        const isOneOfLastThree = index > totalPages - 4;
        const isSelectedOneOfLastThree = currentPage > totalPages - 3;

        if (
          isOneOfFirstThree ||
          (isSelectedOneOfLastThree && isOneOfLastThree) ||
          index === totalPages - 1
        ) {
          return (
            <button
              key={index}
              className={`${
                index + 1 === Number(currentPage) ? styles.active : ""
              } ${styles.pageButton}`}
              onClick={() => onPageChange(index + 1)}
            >
              {index + 1}
            </button>
          );
        } else if (index === 3 && !isSelectedOneOfLastThree) {
          // For the 4th position, show "..."
          return <span key={index}>...</span>;
        } else if (index === totalPages - 4 && isSelectedOneOfLastThree) {
          // For the position before the last three when one of the last three is selected
          return <span key={index}>...</span>;
        }
        return null; // For all other positions
      })}
    </div>
  );
};

export default Pagination;
