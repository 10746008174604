import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./FollowingDoctors.css";

interface Doctor {
  id: number;
  doctor_id: number;
  doctor_type: string;
  first_name: string;
  last_name: string;
  specialty: string;
}

interface Props {
  doctors: Doctor[];
  unfollowDoctor: (id: number, doctor_type: string) => Promise<void>;
}

const FollowingDoctors: React.FC<Props> = ({
  doctors: initialDoctors,
  unfollowDoctor,
}) => {
  const { t } = useTranslation();
  const [doctors, setDoctors] = useState<Doctor[]>(initialDoctors);

  const handleUnfollowDoctor = async (id: number, doctor_type: string) => {
    try {
      await unfollowDoctor(id, doctor_type);
      setDoctors((prevDoctors) =>
        prevDoctors.filter(
          (doctor) =>
            !(doctor.doctor_id === id && doctor.doctor_type === doctor_type)
        )
      );
    } catch (error) {
      console.error("Failed to unfollow doctor:", error);
    }
  };

  useEffect(() => {
    setDoctors(initialDoctors);
  }, [initialDoctors]);

  return (
    <div className="following-doctors">
      <h2>{t("Profile.Following_Doctors")}</h2>
      <ul className="following-doctors-list">
        {doctors.map((doctor) => (
          <li
            key={`${doctor.id}-${doctor.doctor_type}`}
            className="doctor-item"
          >
            <div className="doctor-info">
              <strong>
                {doctor.first_name} {doctor.last_name}
              </strong>
              <span>
                {t("Doctor.Specialty")}: {doctor.specialty}
              </span>
            </div>
            <button
              className="unfollow-btn"
              onClick={() =>
                handleUnfollowDoctor(doctor.doctor_id, doctor.doctor_type)
              }
            >
              {t("Profile.Unfollow")}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FollowingDoctors;
