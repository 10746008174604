import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./form.css";
import ReactStars from "react-rating-stars-component";

type ReviewFormProps = {
  onSubmit: (rating: number, reviewText: string) => void;
};

const ReviewForm: React.FC<ReviewFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [rating, setRating] = useState<number>(0);
  const [review, setReview] = useState<string>("");
  const [reviewText, setReviewText] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(rating, review);
  };

  return (
    <div className="bestabib__rating-section">
      {" "}
      <ReactStars
        count={5}
        value={rating}
        size={19}
        activeColor="#ffd700"
        isHalf={true}
        edit={true}
        onChange={(newRatingValue: any) => {
          setRating(newRatingValue);
          //   handleRatingSubmit(newRatingValue);
        }}
      />
      <form className="review-form" onSubmit={handleSubmit}>
        <textarea
          value={review}
          onChange={(e) => setReview(e.target.value)}
          placeholder={t("Messages.Write your review here...") || ""}
        />
        <button type="submit">{t("Messages.Submit_Review")}</button>
      </form>
    </div>
  );
};

export default ReviewForm;
