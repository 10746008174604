import axios from 'axios';
import {
  BLOG_LIST_REQUEST,
  BLOG_LIST_SUCCESS,
  BLOG_LIST_FAIL,
  BLOG_DETAILS_REQUEST,
  BLOG_DETAILS_SUCCESS,
  BLOG_DETAILS_FAIL,
} from '../constants/blogConstants';

// Fetch all blogs
export const listBlogs = () => async (dispatch: any) => {
  try {
    dispatch({ type: BLOG_LIST_REQUEST });

    const { data } = await axios.get('/api/users/blogs/');

    dispatch({
      type: BLOG_LIST_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: BLOG_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Fetch a single blog by its ID
export const fetchBlogById = (id: string) => async (dispatch: any) => {
  try {
    dispatch({ type: BLOG_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/users/blogs/${id}/`);

    dispatch({
      type: BLOG_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: BLOG_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
