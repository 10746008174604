// src/utils.ts

// This function converts a given string into a URL-friendly slug
export const createSlug = (name: string): string => {
    return name
      .toLowerCase()
      .normalize("NFD") // Normalize the string to decompose special characters
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
      .replace(/[^a-z0-9 -]/g, "") // Remove any characters that are not alphanumeric, spaces, or hyphens
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/-+/g, "-"); // Remove multiple hyphens in a row
  };
  