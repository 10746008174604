import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Loader from "../../components/Loader";
import Message from "../../components/Message";
import Pagination from "../../components/Pagination";
import { listDoctors } from "../../actions/doctorActions";
import { listBlogs } from "../../actions/blogActions";
import { DoctorType } from "../../types";
import { createSlug } from "../../utils/utils";
import { SearchBox, DoctorList, BlogList } from "../../components";
import "./home.css";

interface SearchParams {
  doctor_name: string;
  specialty: string;
  city: string;
  gender: string;
}

const HomeScreen: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedDoctor, setSelectedDoctor] = useState<DoctorType | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [shouldSearch, setShouldSearch] = useState(false); // Track if user has clicked search
  const [searchParams, setSearchParams] = useState<SearchParams>({
    doctor_name: "",
    specialty: "",
    city: "",
    gender: "",
  });

  const codeLng = window.location.pathname.substring(1, 3) || "fr";

  const {
    error: doctorError,
    loading: loadingDoctors,
    doctors,
    totalPages,
  } = useSelector((state: any) => state.doctorList);
  const {
    error: blogError,
    loading: loadingBlogs,
    blogs,
  } = useSelector((state: any) => state.blogList);

  useEffect(() => {
    if (shouldSearch) {
      // Only load doctors if the user has clicked search
      dispatch<any>(listDoctors({ ...searchParams, currentPage }));
    }
    dispatch<any>(listBlogs());
  }, [dispatch, searchParams, currentPage, shouldSearch]);

  const handleDoctorClick = (doctor: DoctorType) => {
    setSelectedDoctor(doctor);
    const doctorSlug = `${createSlug(doctor.first_name ?? "")}-${createSlug(
      doctor.last_name ?? ""
    )}`;
    const specialtySlug = doctor?.speciality
      ? createSlug(doctor?.speciality)
      : "medecin";

    if (window.innerWidth < 920) {
      navigate(`/${codeLng}/${specialtySlug}/${doctorSlug}`, {
        state: { doctorId: doctor.id },
      });
    }
  };

  const handleBlogClick = (blogId: number, title: string) => {
    const blogSlug = createSlug(title);
    navigate(`/${codeLng}/blog/${blogSlug}/${blogId}`);
  };

  const handleSearch = () => {
    setShouldSearch(true); // Mark that the search has been triggered
  };

  return (
    <>
      <div className="home-container">
        <div className="search-box">
          <SearchBox
            setSearchParams={setSearchParams}
            handleSearch={handleSearch}
          />
        </div>

        {loadingDoctors ? (
          <Loader />
        ) : doctorError ? (
          <Message variant="danger">{doctorError}</Message>
        ) : (
          <>
            {/* Only show "Best Doctors" and the doctor list if doctors have been loaded */}

            {/* Only show pagination after doctor list is loaded */}
            {shouldSearch && (
              <>
                <DoctorList
                  doctors={doctors}
                  loading={loadingDoctors}
                  error={doctorError}
                  selectedDoctor={selectedDoctor}
                  onDoctorClick={handleDoctorClick}
                />
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default HomeScreen;
