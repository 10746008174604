import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateUserPassword } from '../../actions/userActions';
import { toast } from 'react-toastify';
import './UpdatePasswordSection.css';

const UpdatePasswordSection = () => {
  const { t } = useTranslation();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const dispatch = useDispatch();

  const handlePasswordUpdate = (e: React.FormEvent) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      toast.error(t('Messages.Error_Passwords_do_not_match!'));
      return;
    }

    dispatch<any>(updateUserPassword(currentPassword, newPassword))
      .then(() => {
        toast.success(t('Messages.Password_updated_successfully!'));
      })
      .catch((error: any) => {
        toast.error(
          error.response?.data?.error || t('Messages.Error_updating_password!')
        );
      });
  };

  return (
    <div className="update-password-section">
      <h2 className="section-title">{t('Profile.UpdatePassword')}</h2>
      <form onSubmit={handlePasswordUpdate} className="update-password-form">
        <div className="form-group">
          <label>{t('Profile.CurrentPassword')}:</label>
          <input
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>{t('Profile.NewPassword')}:</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>{t('Register.Confirm Password')}:</label>
          <input
            type="password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            className="form-control"
          />
        </div>
        <button type="submit" className="btn btn-primary">
          {t('Update')}
        </button>
      </form>
    </div>
  );
};

export default UpdatePasswordSection;
