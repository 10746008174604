import React from "react";
import bestabib_tb from "../../assets/bestabib_logo.png";
import "./footer.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";
import i18n from "../../i18n";

const Footer = () => {
  const { t } = useTranslation();
  const codeLng = i18n.language;
  const dir = i18n.language === "ar" ? "rtl" : "ltr";

  return (
    <div className="bestabib__footer section__padding" dir={dir}>
      <div className="bestabib__footer-links">
        <div className="bestabib__footer-links_logo">
          <img src={bestabib_tb} alt="bestabib_logo" />
        </div>
        <div className="bestabib__footer-links_div">
          <h4>{t("Bestabib.Company")}</h4>
          <p>
            <Link to={`${codeLng}/about`}>{t("Bestabib.About us")}</Link>
          </p>
          <p>
            <Link to={`${codeLng}/terms`}>
              {t("Bestabib.Terms of Service")}
            </Link>
          </p>
          <p>
            <Link to={`${codeLng}/privacy`}>
              {t("Bestabib.Privacy Policy")}
            </Link>
          </p>
        </div>
        <div className="bestabib__footer-links_div">
          <h4>{t("Bestabib.Links")}</h4>
          <p>
            <a
              className="responsiveSiteFooter__socialLink"
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.facebook.com/groups/599887746741446"
            >
              <FaFacebook size="1.5em" />
            </a>
          </p>
          <p>
            <Link to={`${codeLng}/profile/`}>
              {t("Doctor_Details.Add_a_Doctor")}
            </Link>
          </p>
        </div>

        <div className="bestabib__footer-links_div" id="contact">
          <h4>{t("Bestabib.Get in touch")}</h4>
          <p>{t("Bestabib.Rabat, Morocco")}</p>
          <p>{t("Bestabib.Contact")}: bestabib@gmail.com</p>
        </div>
      </div>
      <div className="bestabib__footer-copyright">
        <p>
          {t("Bestabib.Copyright © 2024 Bestabib Inc. All rights reserved.")}
        </p>
      </div>
    </div>
  );
};

export default Footer;
