import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import i18n from "../../i18n";
import { register } from "../../actions/userActions";
import "./register.css";

const RegisterScreen = (location: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dir = i18n.language === "ar" ? "rtl" : "ltr";
  const codeLng = window.location.pathname.substring(1, 3) || "fr";
  const redirect = location.search
    ? location.search.split("=")[1]
    : `/${codeLng}/profile/`;
  const userRegister = useSelector((state: any) => state.userRegister);
  const { userInfo, error } = userRegister;

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, userInfo, redirect]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("Register.Name is required") as string),
    email: Yup.string()
      .email(t("Register.Enter a valid email") as string)
      .required(t("Register.Email is required") as string),
    password: Yup.string()
      .min(4, t("Register.Password must be at least 8 characters") as string)
      .required(t("Register.Password is required") as string),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), undefined],
        t("Register.Passwords must match") as string
      )
      .required(t("Register.Confirm Password is required") as string),
  });

  const initialValues = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    isDoctor: false,
  };

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    dispatch<any>(
      register(values.name, values.email, values.password, values.isDoctor)
    );
    setSubmitting(false);
  };

  return (
    <div className="bestabib-register-form">
      {error && <div className="error-message">{t(error)}</div>}

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            {" "}
            <label>
              <Field type="checkbox" name="isDoctor" /> {t("Register.isDoctor")}
            </label>
            <Field
              type="text"
              name="name"
              placeholder={t("Register.Name")}
              className="bestabib-input"
              autoComplete="on"
            />
            <ErrorMessage
              name="name"
              render={(msg) => (
                <div className="error-message">{t("Error.NameRequired")}</div>
              )}
            />
            <Field
              type="email"
              name="email"
              placeholder={t("Register.Enter Email")}
              className="bestabib-input"
              autoComplete="on"
            />
            <ErrorMessage
              name="email"
              render={(msg) => (
                <div className="error-message">{t("Error.EmailRequired")}</div>
              )}
            />
            <Field
              type="password"
              name="password"
              placeholder={t("Register.Enter Password")}
              className="bestabib-input"
              autoComplete="on"
            />
            <ErrorMessage
              name="password"
              render={(msg) => (
                <div className="error-message">
                  {t("Error.PasswordRequired")}
                </div>
              )}
            />
            <Field
              type="password"
              name="confirmPassword"
              placeholder={t("Register.Confirm Password")}
              className="bestabib-input"
              autoComplete="on"
            />
            <ErrorMessage
              name="confirmPassword"
              render={(msg) => (
                <div className="error-message">
                  {t("Error.ConfirmPasswordRequired")}
                </div>
              )}
            />
            <p dir={dir} className="bestabib__register-form-body-agreement">
              {t("Bestabib.By creating an account, you agree to the Bestabib")}
              <Link to={`/${codeLng}/terms/`}>
                {" "}
                {t("Bestabib.Terms of Service")}
              </Link>
              {", "}
              <Link to={`/${codeLng}/privacy/`}>
                {t("Bestabib.Privacy Policy")}
              </Link>
              {t("Bestabib., and ")}{" "}
              <Link to={`/${codeLng}/cookies-policy/`}>
                {t("Bestabib.Cookie Policy")}
              </Link>
            </p>
            <button
              type="submit"
              disabled={isSubmitting}
              className="bestabib-button"
            >
              {t("Register.Sign Up")}
            </button>
          </Form>
        )}
      </Formik>
      <p className="bestabib-register-form_login">
        {t("Register.Have an account?")}{" "}
        <Link to={`/${codeLng}/login/`}>{t("Login.Sign In")}</Link>
      </p>
    </div>
  );
};

export default RegisterScreen;
