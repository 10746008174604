import React from "react";
import { Spinner } from "react-bootstrap";
import "./Loader.css"; // Import the custom CSS file

function Loader() {
  return (
    <div className="loader-container">
      <Spinner animation="border" role="status" className="custom-spinner">
        <span className="sr-only">Loading...</span>
      </Spinner>
      <p className="loader-text">Loading...</p>
    </div>
  );
}

export default Loader;
