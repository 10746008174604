import { number } from "yup";
import {
  DOCTOR_LIST_REQUEST,
  DOCTOR_LIST_SECCESS,
  DOCTOR_LIST_FAIL,
  DOCTOR_REVIEW_REQUEST,
  DOCTOR_REVIEW_SUCCESS,
  DOCTOR_REVIEW_FAIL,
  DOCTOR_FETCH_REQUEST,
  DOCTOR_FETCH_SUCCESS,
  DOCTOR_FETCH_FAIL,
} from "../constants/doctorConstants";

export const doctorListReducer = (state = { doctors: [] }, action: any) => {
  switch (action.type) {
    case DOCTOR_LIST_REQUEST:
      return { loading: true, doctors: [] };

    case DOCTOR_LIST_SECCESS:
      return {
        loading: false,
        doctors: action.payload.doctors,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
        doctorsPerPage: action.payload.doctorsPerPage,
      };

    case DOCTOR_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

const initialState = {
  byId: {},
  allIds: [],
  loading: false,
  error: null,
};

export const doctorReviewReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case DOCTOR_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DOCTOR_REVIEW_SUCCESS:
      const key = `${action.payload.doctorId}-${action.payload.doctorType}`;
      return {
        ...state,
        loading: false,
        byId: {
          ...state.byId,
          [key]: {
            averageRating: action.payload.averageRating,
            count: action.payload.count,
            reviews: action.payload.reviews,
          },
        },
      };
    case DOCTOR_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const doctorDetailsReducer = (state = { doctor: {} }, action: any) => {
  switch (action.type) {
    case DOCTOR_FETCH_REQUEST:
      return { loading: true, ...state };
    case DOCTOR_FETCH_SUCCESS:
      return { loading: false, doctor: action.payload };
    case DOCTOR_FETCH_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
