// src/services/reviewService.ts

import axios from 'axios';
import {  useSelector } from "react-redux";


const REVIEWS_URL = '/api/doctors/reviews/'; 

export const loadReviews = async (doctorId: string, token: string, doctorType:string) => {
      const config = token ? {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      } : {};
  const response = await axios.get(`${REVIEWS_URL}?doctor=${doctorId}&doctor_type=${doctorType}`, config);
  return response.data.reviews;
};

export const addReview = async (doctorId: number, rating: number, review: string,  token: string) => {
   
    const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
  const response = await axios.post(REVIEWS_URL, { doctor: doctorId, rating, review }, config);

  return response.data;
};

export const updateReview = async (reviewId: number, rating: number, review: string, token: string) => {
    const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
  const response = await axios.put(`${REVIEWS_URL}${reviewId}/`, { rating, review }, config);
  return response.data;
};
