import { useEffect } from "react";
import "./login.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { login } from "../../actions/userActions";
import { useNavigate } from "react-router-dom";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(4, "Password must be at least 8 characters")
    .required("Password is required"),
});

function LoginScreen(location: any) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const codeLng = window.location.pathname.substring(1, 3) || "fr";

  const redirect = location.search
    ? location.search.split("=")[1]
    : `/${codeLng}/profile/`;

  const userLogin = useSelector((state: any) => state.userLogin);

  const { error, userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, userInfo, redirect, error]);
  const handleSubmit = (values: any, { setSubmitting }: any) => {
    dispatch<any>(login(values.email, values.password));
    setSubmitting(false);
  };

  return (
    <div className="login-page">
      {error && <div className="error-message">{t(error)}</div>}
      <div className="login-container">
        <div className="login-header">
          <h1 className="jobmaroc__login-container_header__content__heading">
            {t("Login.Sign In")}
          </h1>
          <p className="jobmaroc__login-container_header__content__subheading">
            {t("* وَإِذَا مَرِضْتُ فَهُوَ يَشْفِينِ *")}
          </p>
        </div>

        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <FormikForm className="login-form">
            <Field
              type="email"
              name="email"
              placeholder={t("Login.Email")}
              className="login-form_input-field"
              autoComplete="on"
            />
            <ErrorMessage
              name="email"
              component="div"
              render={(msg) => (
                <div className="error-message">{t("Error.EmailRequired")}</div>
              )}
            />

            <Field
              type="password"
              name="password"
              placeholder={t("Login.Password")}
              className="login-form_input-field"
              autoComplete="on"
            />
            <ErrorMessage
              name="password"
              render={(msg) => (
                <div className="error-message">
                  {t("Error.PasswordRequired")}
                </div>
              )}
            />

            <Link
              to={`/${codeLng}/reset-password/`}
              className="forgot-password"
            >
              {t("Login.Forgot your Password?")}
            </Link>

            <button type="submit" className="login-button">
              {t("Login.Sign In")}
            </button>
          </FormikForm>
        </Formik>
        <div className="register-link">
          <p className="jobmarok-login-form_register">
            {t("Login.New Customer?")}{" "}
            <Link to={`/${codeLng}/register/`}>{t("Register.Sign Up")}</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default LoginScreen;
