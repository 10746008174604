import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "./Doctor.css";
import ReviewForm from "../Form/ReviewForm";
import { useNavigate } from "react-router-dom";
import i18n from "../../i18n";
import { notifyErr, notifySuccess } from "../MessageUtils";
import { doctorReview } from "../../actions/doctorActions";

const RatingSection = ({
  doctorId,
  isDoctorDetails,
  doctorType,
}: {
  doctorId: string;
  isDoctorDetails: boolean;
  doctorType: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const codeLng = i18n.language;
  const key = `${doctorId}-${doctorType}`;
  const reviewForDoctor = useSelector((state: any) => state.reviews.byId[key]);

  const [averageRating, setAverageRating] = useState<number>(0.0);
  const [count, setCount] = useState<number>(0.0);

  const userLogin = useSelector((state: any) => state.userLogin);
  const { userInfo } = userLogin;

  const [isFormVisible, setIsFormVisible] = useState(false);

  useEffect(() => {
    if (doctorId) {
      dispatch<any>(doctorReview(doctorId, doctorType));
    }
  }, [dispatch, doctorId, doctorType]);

  useEffect(() => {
    setAverageRating(reviewForDoctor?.averageRating);
    setCount(reviewForDoctor?.count);
  }, [doctorId, reviewForDoctor?.averageRating]);

  const handleRatingSubmit = async (newRatingValue: number, review: string) => {
    try {
      if (newRatingValue) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        };

        await axios.post(
          "/api/doctors/reviews/",
          {
            rating: newRatingValue,
            review: review,
            doctor: doctorId,
            user: userInfo.id,
            doctor_type: doctorType,
          },
          config
        );
        handleReviewSubmitSuccess();
        dispatch<any>(doctorReview(doctorId, doctorType));
      } else {
        notifyErr(t("Error.Please_Choose_a_rating"));
      }
    } catch (error: any) {
      console.error(
        "Failed to submit rating:",
        error?.response?.data || error.message
      );
    }
  };

  const writeReview = (e: any) => {
    if (!userInfo) {
      navigate(`/${codeLng}/login/`);
    }
    setIsFormVisible(!isFormVisible);
  };

  const handleReviewSubmitSuccess = () => {
    notifySuccess(t("Messages.Your_review_has_been_submitted_successfully!"));
    setIsFormVisible(false);
  };
  return (
    <div className="bestabib__rating-section">
      <div className="stars">
        <ReactStars
          key={averageRating}
          value={averageRating}
          size={16}
          activeColor="#ffd700"
          isHalf={true}
          edit={false}
        />
        <span>({count})</span>
      </div>

      {isDoctorDetails && (
        <div>
          <span className="write-review-button" onClick={writeReview}>
            {t("Messages.Write_a_Review")}
          </span>

          <div
            className={`bestabib__rating-section-review-form ${
              isFormVisible ? "visible" : ""
            }`}
          >
            <ReviewForm onSubmit={handleRatingSubmit} />
          </div>
        </div>
      )}
    </div>
  );
};

export default RatingSection;
