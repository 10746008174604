import React from 'react';
import './brand.css';

const Brand = () => (
  <section
    className="bestabib__brand section__padding"
    aria-labelledby="brand-section-title"
  >
    {/* Semantic Section with a Descriptive Heading */}
    <h1 id="brand-section-title" className="visually-hidden">
      Websites That Trust and Collaborate with Us
    </h1>

    {/* Structured Data for SEO */}
    <script type="application/ld+json">
      {JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: 'Bestabib',
        url: 'https://www.bestabib.com',
        brand: [
          {
            '@type': 'Brand',
            name: 'Jobmarok',
            url: 'https://www.bestabib.com/jobmarok',
          },
          {
            '@type': 'Brand',
            name: 'Namadej',
            url: 'https://www.bestabib.com/namadej',
          },
          {
            '@type': 'Brand',
            name: 'Kinzbook',
            url: 'https://www.bestabib.com/kinzbook',
          },
        ],
      })}
    </script>

    {/* Brand Display */}
    <div className="gradient__text">
      <h2>
        <a
          href="https://www.jobmarok.com"
          target="_blank"
          title="Visit Jobmarok - Jobs in Morocco"
        >
          Jobmarok
        </a>
      </h2>
    </div>
    <div className="gradient__text">
      <h2>
        <a
          href="https://www.namadej.com"
          target="_blank"
          title="Visit Namadej - Modèle de lettre"
        >
          Namadej
        </a>
      </h2>
    </div>
    <div className="gradient__text">
      <h2>
        <a
          href="https://www.kinzbook.com"
          target="_blank"
          title="Visit Kinzbook - Resources and Books"
        >
          Kinzbook
        </a>
      </h2>
    </div>
  </section>
);

export default Brand;
