import { Suspense } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import PrivateRoutes from './utils/PrivateRoutes';
import { Container } from 'react-bootstrap';
import { BlogList, Navbar } from './components';
import { Footer } from './containers';

import {
  RegisterScreen,
  LoginScreen,
  HomeScreen,
  TermsScreen,
  PrivacyScreen,
  AboutScreen,
  PrivacyCookiesScreen,
  ResetPasswordScreen,
  ResetPasswordConfirmScreen,
  ProfileScreen,
} from './screens';
import DoctorDetailsPage from './screens/doctors/DoctorDetailsPage';
import RedirectToDefaultLanguage from './RedirectToDefaultLanguage';
import BlogDetails from './components/blogs/BlogDetails';
import Brand from './components/brand/Brand';

function App() {
  return (
    <div className="App">
      <Suspense fallback={null}>
        <Router>
          <AppContent />
        </Router>
      </Suspense>
    </div>
  );
}

// Separate component to use location within Router context
function AppContent() {
  const location = useLocation();

  return (
    <div className="gradient__bg">
      <Navbar />
      <Routes>
        <Route path="/" element={<RedirectToDefaultLanguage />} />
        <Route path={`/:codeCountry/`} element={<HomeScreen />} />
        <Route path="/:id/" element={<HomeScreen />} />
        <Route
          path="/:codeLng/:speciality/:doctorSlug"
          element={<DoctorDetailsPage />}
        />
        <Route path={`/:codeCountry/about/`} element={<AboutScreen />} />
        <Route path={`/:codeCountry/terms/`} element={<TermsScreen />} />
        <Route path={`/:codeCountry/privacy/`} element={<PrivacyScreen />} />
        <Route
          path={`/:codeCountry/cookies-policy/`}
          element={<PrivacyCookiesScreen />}
        />
        <Route element={<PrivateRoutes />}>
          <Route path={`/:codeCountry/profile/`} element={<ProfileScreen />} />
        </Route>
        <Route path={`/:codeCountry/register/`} element={<RegisterScreen />} />
        <Route path={`/:codeCountry/login/`} element={<LoginScreen />} />
        <Route
          path={`/:codeCountry/reset-password/`}
          element={<ResetPasswordScreen />}
        />
        <Route
          path={`/:codeCountry/password/reset/confirm/:uid/:token`}
          element={<ResetPasswordConfirmScreen />}
        />
        <Route
          path="/:codeCountry/blog/:blogSlug/:blogId"
          element={<BlogDetails />}
        />
      </Routes>
      {/* Conditionally render BlogList if not on the profile page */}
      <Brand />
      {!location.pathname.includes('/profile, /blog') && <BlogList />}
      <Footer />
    </div>
  );
}

export default App;
