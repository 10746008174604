// blogConstants.js
export const BLOG_LIST_REQUEST = 'BLOG_LIST_REQUEST';
export const BLOG_LIST_SUCCESS = 'BLOG_LIST_SUCCESS';
export const BLOG_LIST_FAIL = 'BLOG_LIST_FAIL';
export const BLOG_DETAILS_REQUEST = 'BLOG_DETAILS_REQUEST';
export const BLOG_DETAILS_SUCCESS = 'BLOG_DETAILS_SUCCESS';
export const BLOG_DETAILS_FAIL = 'BLOG_DETAILS_FAIL';

export const BLOG_ADD_SUCCESS = 'BLOG_ADD_SUCCESS';
export const BLOG_ADD_FAIL = 'BLOG_ADD_FAIL';

export const BLOG_UPDATE_SUCCESS = 'BLOG_UPDATE_SUCCESS';
export const BLOG_UPDATE_FAIL = 'BLOG_UPDATE_FAIL';

export const BLOG_DELETE_SUCCESS = 'BLOG_DELETE_SUCCESS';
export const BLOG_DELETE_FAIL = 'BLOG_DELETE_FAIL';
