import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RatingSection from "../../components/doctor/RatingSection";
import "./Doctor.css";
import { DoctorType, UserType } from "../../types";

interface DoctorProps {
  doctor: any;
}

const Doctor: React.FC<DoctorProps> = ({ doctor }) => {
  const { doctor_profile, first_name, last_name, address } = doctor;
  const { street, postal_code, city } = address || {};
  const doctorId = doctor_profile ? doctor_profile?.id : doctor.id || null;
  const doctorType = doctor.doctor_profile ? "doctor_profile" : "doctor";

  return (
    <div className="doctor-card">
      <img
        className="doctor-card-image"
        // src={doctor_profile?.image ? doctor_profile?.image : doctor?.image}
        src={
          doctor?.doctor_profile?.image
            ? doctor?.doctor_profile?.image
            : doctor?.gender === "F"
            ? "https://tallshadowawsbucket.s3.eu-west-3.amazonaws.com/bestabib/default-doctor-f.jpg"
            : "https://tallshadowawsbucket.s3.eu-west-3.amazonaws.com/bestabib/default-doctor-m.jpg"
        }
        alt={last_name}
        width={88}
        height={88}
      />
      <div className="doctor-card-info">
        <h3 className="doctor-card-title">
          Dr. {first_name} {last_name}
        </h3>
        <p className="doctor-card-speciality">
          {doctor_profile?.speciality
            ? doctor_profile?.speciality
            : doctor?.speciality}
        </p>
        <div className="bestabib__doctor-details-card-info-ratings">
          {doctorId && (
            <RatingSection
              doctorId={doctorId}
              isDoctorDetails={false}
              doctorType={doctorType}
            />
          )}
        </div>
        <p className="doctor-card-address">
          {street && street + ","} {postal_code && postal_code + ","} {city}
        </p>
        <hr />
        <p
          dangerouslySetInnerHTML={{
            __html: doctor_profile?.description?.slice(0, 99) || "",
          }}
        />
      </div>
    </div>
  );
};

export default Doctor;
