import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  fetchFollowingDoctorsReducer,
  blogListReducer,
  userBlogsReducer,
} from './reducers/userReducers';
import {
  doctorListReducer,
  doctorReviewReducer,
  doctorDetailsReducer,
} from './reducers/doctorReducers';

import { blogDetailsReducer } from './reducers/blogReducer';

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  doctorList: doctorListReducer,
  reviews: doctorReviewReducer,
  doctorDetails: doctorDetailsReducer,
  fetchFollowingDoctors: fetchFollowingDoctorsReducer,
  blogList: blogListReducer,
  blogDetails: blogDetailsReducer,
  userBlogs: userBlogsReducer,
});

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo') || '')
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
