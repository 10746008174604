import React, { FC, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PiStethoscopeDuotone } from 'react-icons/pi';
import { GoLocation } from 'react-icons/go';
import { FaUserMd } from 'react-icons/fa';
import './SearchBox.css';

interface SearchParams {
  doctor_name: string;
  specialty: string;
  city: string;
  gender: string;
}

interface SearchBoxProps {
  setSearchParams: React.Dispatch<React.SetStateAction<SearchParams>>;
  handleSearch: () => void;
}

const SearchBox: FC<SearchBoxProps> = ({ setSearchParams, handleSearch }) => {
  const { t } = useTranslation();
  const [params, setParams] = useState<SearchParams>({
    doctor_name: '',
    specialty: '',
    city: '',
    gender: 'A', // Default value for "All" gender selection
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setParams((prevParams) => ({ ...prevParams, [name]: value }));
  };

  const handleGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setParams((prevParams) => ({
      ...prevParams,
      gender: value,
    }));
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setSearchParams(params); // Update the search parameters in the parent component
    handleSearch(); // Trigger the search action in HomeScreen
  };

  return (
    <div className="section__margin">
      <div className="searchBox-heading gradient__text ">
        <h1>{t('Search.Find_Phrase')}</h1>
      </div>
      <form className="searchBox-Container" onSubmit={handleSubmit}>
        {/* Add the translated phrase above the search box */}

        <div className="inputs-container">
          <div className="input-icon-container">
            <input
              type="text"
              placeholder={t('Doctor.Specialty') || ''}
              name="specialty"
              value={params.specialty}
              onChange={handleInputChange}
              className="searchInput specialty"
            />
            <PiStethoscopeDuotone className="icon" />
          </div>
          <div className="input-icon-container">
            <input
              type="text"
              placeholder={t('Search.City') || ''}
              name="city"
              value={params.city}
              onChange={handleInputChange}
              className="searchInput city"
            />
            <GoLocation className="icon" />
          </div>
          <div className="input-icon-container">
            <input
              type="text"
              placeholder={t('Doctor.Doctor_Name') || ''}
              name="doctor_name"
              value={params.doctor_name}
              onChange={handleInputChange}
              className="searchInput doctor"
            />
            <FaUserMd className="icon" />
          </div>
        </div>
        <div className="gender-container">
          <label>
            <input
              type="radio"
              name="gender"
              value="A"
              checked={params.gender === 'A'}
              onChange={handleGenderChange}
            />
            {t('All')}
          </label>
          <label>
            <input
              type="radio"
              name="gender"
              value="M"
              checked={params.gender === 'M'}
              onChange={handleGenderChange}
            />
            {t('Doctor.Male')}
          </label>
          <label>
            <input
              type="radio"
              name="gender"
              value="F"
              checked={params.gender === 'F'}
              onChange={handleGenderChange}
            />
            {t('Doctor.Female')}
          </label>
        </div>
        <button type="submit" className="primary-cta">
          {t('Search.find_a_doctor')}
        </button>
      </form>
    </div>
  );
};

export default SearchBox;
