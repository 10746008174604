import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBlogById } from '../../actions/blogActions';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import './BlogDetails.css';
const BlogDetails = () => {
  const { blogId } = useParams();
  const dispatch = useDispatch();

  const blog = useSelector((state: any) => state.blogDetails.blog);
  const { error, loading } = useSelector((state: any) => state.blogDetails);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (blogId) {
      dispatch<any>(fetchBlogById(blogId));
    }
  }, [dispatch, blogId]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!blog) {
    return <div className="no-blog">No blog found.</div>;
  }

  return (
    <div className="blog-details-container">
      <h1 className="blog-title">{blog.title}</h1>
      <div className="blog-content">
        <ReactQuill
          value={blog.content}
          readOnly
          theme="snow"
          modules={{ toolbar: false }} // Disable the toolbar
        />
      </div>
    </div>
  );
};

export default BlogDetails;
