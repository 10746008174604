import axios from "axios";
import {
  DOCTOR_LIST_REQUEST,
  DOCTOR_LIST_SECCESS,
  DOCTOR_LIST_FAIL,
  REVIEW_CREATE_REQUEST,
  REVIEW_CREATE_SECCESS,
  DOCTOR_REVIEW_REQUEST,
  DOCTOR_REVIEW_SUCCESS,
  DOCTOR_REVIEW_FAIL,
  LOAD_REVIEWS_REQUEST,
  LOAD_REVIEWS_SUCCESS,
  LOAD_REVIEWS_FAILURE,
  DOCTOR_FETCH_REQUEST,
  DOCTOR_FETCH_SUCCESS,
  DOCTOR_FETCH_FAIL,
} from "../constants/doctorConstants";

interface SearchParams {
  specialty?: string;
  city?: string;
  doctor_name?: string;
  gender?: string;
  currentPage?: number;
}

interface ReviewParams {
  doctorId: string;
  city?: string;
  doctor_name?: string;
  gender?: string;
}
export const listDoctors =
  (keyword?: SearchParams) => async (dispatch: any) => {
    try {
      dispatch({ type: DOCTOR_LIST_REQUEST });

      const { data } = await axios.get(`/api/doctors/doctor-search/`, {
        params: keyword,
      });

      dispatch({
        type: DOCTOR_LIST_SECCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: DOCTOR_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const doctorReview =
  (doctorId: string, doctorType: string) => async (dispatch: any) => {
    try {
      dispatch({ type: DOCTOR_REVIEW_REQUEST });

      const config = {
        headers: {
          "Content-type": "application/json",
          // Authorization: `Bearer ${userInfo.token}`,
        },
        params: {
          doctor: doctorId,
          doctor_type: doctorType,
        },
      };

      const { data } = await axios.get(`/api/doctors/reviews/`, config);

      dispatch({
        type: DOCTOR_REVIEW_SUCCESS,
        payload: {
          doctorId,
          doctorType,
          averageRating: data.averageRating,
          count: data.count,
          reviews: data.reviews,
        },
      });
    } catch (error: any) {
      dispatch({
        type: DOCTOR_REVIEW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const fetchDoctorReview = (doctorId: any) => async (dispatch: any) => {
  dispatch({ type: DOCTOR_REVIEW_REQUEST });
  try {
    const response = await axios.get(`/api/doctors/reviews/`, {
      params: {
        doctor: doctorId,
      },
    });
    dispatch({
      type: DOCTOR_REVIEW_SUCCESS,
      payload: { doctorId, reviewData: response.data },
    });
  } catch (error: any) {
    dispatch({
      type: DOCTOR_REVIEW_FAIL,
      payload: error.response && error.response.data.message,
    });
  }
};

export const loadReviews = (doctorId: number) => {
  return async (dispatch: any) => {
    dispatch({ type: LOAD_REVIEWS_REQUEST });
    try {
      const response = await axios.get(`/api/doctors/get/review/${doctorId}/`);

      dispatch({
        type: LOAD_REVIEWS_SUCCESS,
        payload: response.data,
        // payload: {
        //   averageRating: response.data.averageRating,
        //   count: response.data.count,
        //   doctorId,
        // },
      });
    } catch (error: any) {
      dispatch({
        type: LOAD_REVIEWS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const addReview =
  (newRatingValue: number, review: string, doctorId: string) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: REVIEW_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        "/api/doctors/reviews/",
        {
          rating: newRatingValue,
          review: review,
          doctor: doctorId,
          user: userInfo.id,
        },
        config
      );

      dispatch({
        type: REVIEW_CREATE_SECCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: REVIEW_CREATE_SECCESS,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const fetchDoctorBySlug = (slug: string) => async (dispatch: any) => {
  try {
    dispatch({ type: DOCTOR_FETCH_REQUEST });

    const { data } = await axios.get(`/api/doctors/slug/${slug}/`);

    dispatch({
      type: DOCTOR_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: DOCTOR_FETCH_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
