export const DOCTOR_LIST_REQUEST = "DOCTOR_LIST_REQUEST";
export const DOCTOR_LIST_SECCESS = "DOCTOR_LIST_SECCESS";
export const DOCTOR_LIST_FAIL = "DOCTOR_LIST_FAIL";

export const DOCTOR_REVIEW_REQUEST = "DOCTOR_REVIEW_REQUEST";
export const DOCTOR_REVIEW_SUCCESS = "DOCTOR_REVIEW_SUCCESS";
export const DOCTOR_REVIEW_FAIL = "DOCTOR_REVIEW_FAIL";

export const REVIEW_CREATE_REQUEST = "REVIEW_CREATE_REQUEST";
export const REVIEW_CREATE_SECCESS = "REVIEW_CREATE_SECCESS";
export const REVIEW_CREATE_FAIL = "REVIEW_CREATE_FAIL";
export const REVIEW_CREATE_RESET = "REVIEW_CREATE_RESET";

export const LOAD_REVIEWS_REQUEST = "LOAD_REVIEWS_REQUEST";
export const LOAD_REVIEWS_SUCCESS = "LOAD_REVIEWS_SUCCESS";
export const LOAD_REVIEWS_FAILURE = "LOAD_REVIEWS_FAILURE";

export const DOCTOR_FETCH_REQUEST = "DOCTOR_FETCH_REQUEST";
export const DOCTOR_FETCH_SUCCESS = "DOCTOR_FETCH_SUCCESS";
export const DOCTOR_FETCH_FAIL = "DOCTOR_FETCH_FAIL";

export const FETCH_FOLLOWING_DOCTORS_REQUEST =
  "FETCH_FOLLOWING_DOCTORS_REQUEST";
export const FETCH_FOLLOWING_DOCTORS_SUCCESS =
  "FETCH_FOLLOWING_DOCTORS_SUCCESS";
export const FETCH_FOLLOWING_DOCTORS_FAILURE =
  "FETCH_FOLLOWING_DOCTORS_FAILURE";
export const FETCH_FOLLOWING_DOCTORS_RESET = "FETCH_FOLLOWING_DOCTORS_RESET";
