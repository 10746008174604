import React, { useEffect, useState } from 'react';
import './BlogSection.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  addBlog,
  deleteBlog,
  getBlogs,
  updateBlog,
} from '../../actions/userActions';
import { useTranslation } from 'react-i18next';

const BlogSection = () => {
  const { t } = useTranslation();
  const [blogTitle, setBlogTitle] = useState('');
  const [blogContent, setBlogContent] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editingBlogId, setEditingBlogId] = useState<number | null>(null);
  const [showForm, setShowForm] = useState(false);

  const dispatch = useDispatch();
  const userLogin = useSelector((state: any) => state.userLogin);
  const { userInfo } = userLogin;

  const blogList = useSelector((state: any) => state.userBlogs.blogs); // Blogs from Redux store

  useEffect(() => {
    // Fetch blogs only if the user is logged in and has an ID
    if (userInfo?.id) {
      dispatch<any>(getBlogs(userInfo.id)); // Fetch blogs for the user
    }
  }, [dispatch, userInfo?.id]);

  // Handle Add/Update Blog
  const handleSaveBlog = (e: React.FormEvent) => {
    e.preventDefault();

    if (!blogTitle || !blogContent) {
      alert('Both title and content are required!');
      return;
    }

    if (isEditing && editingBlogId !== null) {
      // Update Blog
      dispatch<any>(
        updateBlog(editingBlogId, {
          author: userInfo?.id,
          title: blogTitle,
          content: blogContent,
        })
      );
    } else {
      // Add Blog
      dispatch<any>(
        addBlog({
          author: userInfo?.id,
          title: blogTitle,
          content: blogContent,
        })
      );
    }

    setBlogTitle('');
    setBlogContent('');
    setIsEditing(false);
    setEditingBlogId(null);
    setShowForm(false);
  };

  // Handle Delete Blog
  const handleDeleteBlog = (id: number) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this blog?'
    );
    if (confirmDelete) {
      dispatch<any>(deleteBlog(id));
    }
  };

  // Handle Edit Blog
  const handleEditBlog = (id: number, title: string, content: string) => {
    setBlogTitle(title);
    setBlogContent(content);
    setIsEditing(true);
    setEditingBlogId(id);
    setShowForm(true);
  };

  return (
    <div className="blog-section">
      <h2>{t('Blog.Blog_Management')}</h2>

      {/* Add Blog Button */}
      <button onClick={() => setShowForm(!showForm)}>
        {showForm ? t('Button.Cancel') : t('Button.Add_Blog')}
      </button>

      {/* Add/Edit Blog Form */}
      {showForm && (
        <form onSubmit={handleSaveBlog}>
          <div>
            <label>{t('Blog.Title')}</label>
            <br />
            <input
              type="text"
              value={blogTitle}
              onChange={(e) => setBlogTitle(e.target.value)}
              placeholder="Enter blog title"
            />
          </div>
          <div>
            <label>{t('Blog.Content')}</label>
            <ReactQuill
              value={blogContent}
              onChange={setBlogContent}
              theme="snow"
            />
          </div>
          <button type="submit">
            {isEditing ? t('Button.Update') : t('Button.Add')}
          </button>
        </form>
      )}

      {/* Blog List */}
      <div className="blog-list">
        {blogList && blogList.length > 0 ? (
          blogList.map((blog: any) => (
            <div key={blog.id} className="blog-item">
              <h3>{blog.title}</h3>
              <p>{blog.content.slice(0, 100)}...</p>
              <div className="blog-actions">
                <button
                  onClick={() =>
                    handleEditBlog(blog.id, blog.title, blog.content)
                  }
                >
                  {t('Button.Update')}
                </button>
                <button onClick={() => handleDeleteBlog(blog.id)}>
                   {t('Button.Delete')}
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>{t('Blog.No_blogs')}</p>
        )}
      </div>
    </div>
  );
};

export default BlogSection;
