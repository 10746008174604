import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'; // Translation hook
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { listBlogs } from '../../actions/blogActions'; // Fetch blogs action
import './BlogList.css';
import { useNavigate } from 'react-router-dom';

const BlogList: React.FC = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(); // Get i18n instance
  const navigate = useNavigate();
  const [visiblePosts, setVisiblePosts] = useState(6); // Default to 6 posts

  const { loading, error, blogs } = useSelector((state: any) => state.blogList);

  useEffect(() => {
    dispatch<any>(listBlogs()); // Fetch blogs on component mount
  }, [dispatch]);

  const stripHtmlTags = (html: any) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  const handleLoadMore = () => {
    setVisiblePosts((prev) => prev + 3); // Increase visible posts
  };

  const visibleBlogs = Array.isArray(blogs) ? blogs.slice(0, visiblePosts) : [];

  const handleBlogClick = (blogId: number, title: string) => {
    const blogSlug = title.replace(/\s+/g, '-').toLowerCase(); // Generate slug
    const currentLanguage = i18n.language; // Get the current language
    navigate(`/${currentLanguage}/blog/${blogSlug}/${blogId}`);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <div className="blogs-container">
      <div className="blog-list">
        <h1 className="blog-list-title">{t('Blog.LatestPosts')}</h1>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <div className="blog-list-items">
            {visibleBlogs.map((blog: any) => (
              <article
                key={blog.id}
                className="blog-list-item"
                onClick={() => handleBlogClick(blog.id, blog.title)}
              >
                <h2 className="blog-title">{blog.title}</h2>
                <p className="blog-summary">{stripHtmlTags(blog.content)?.slice(0, 150)}...</p>
              </article>
            ))}
          </div>
        )}
        {blogs.length > visiblePosts && (
          <div className="load-more-container">
            <button
              className="load-more-btn"
              aria-label={t('Blog.LoadMore') || 'Load more'}
              onClick={handleLoadMore}
            >
              {t('Blog.LoadMore')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogList;