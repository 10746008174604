import React from 'react';
import { DoctorType } from '../../types';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import Doctor from './Doctor';
import DoctorDetails from './DoctorDetails';
import './DoctorList.css';
import { useTranslation } from 'react-i18next';

interface DoctorListProps {
  doctors: DoctorType[];
  loading: boolean;
  error: string | null;
  selectedDoctor: DoctorType | null;
  onDoctorClick: (doctor: DoctorType) => void;
}

const DoctorList: React.FC<DoctorListProps> = ({
  doctors,
  loading,
  error,
  selectedDoctor,
  onDoctorClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className="doctor-list-container">
      <div className="doctor-list">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <>
            <h1>{t('Doctor.Best Doctors')}</h1>
            {doctors.map((doctor) => (
              <div
                key={doctor.id}
                className={`doctor-card ${
                  selectedDoctor === doctor ? 'selected' : ''
                }`}
                onClick={() => onDoctorClick(doctor)}
              >
                <Doctor doctor={doctor} />
              </div>
            ))}
          </>
        )}
      </div>

      {selectedDoctor && (
        <div className="doctor-details">
          <DoctorDetails selectedDoctor={selectedDoctor} />
        </div>
      )}
    </div>
  );
};

export default DoctorList;
