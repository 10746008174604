import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  // Enables the i18next backend
  .use(Backend)
  // Enable automatic language detection
  .use(LanguageDetector)
  // Enables the hook initialization module
  .use(initReactI18next)
  .init({
    // lng: "fr",
    // Standard language used
    fallbackLng: "fr",
    debug: false,
    //Detects and caches a cookie from the language provided
    detection: {
      order: ["queryString", "cookie"],
      caches: ["cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      // Set the path to your Django backend's API endpoint that serves translation files
      loadPath: "/api/users/i18n/{{lng}}/{{ns}}.json",
    },
  });

export default i18n;
